import React from 'react';
import { string, func } from 'prop-types';
import { navigate } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faExclamationSquare } from '@fortawesome/pro-light-svg-icons';

import { WARNING_ICON, CRITICAL_ICON } from 'constants/font-awesome';
import { Button, FormHelperText } from '@material-ui/core';
import useStyles, { useButtonClasses } from './styles';

const NavMessage = ({
  icon, title,
  message,
  messageTwo = '',
  type,
  route,
  aria,
  onAction = () => {},
}) => {
  const styles = useStyles();
  const buttonClasses = useButtonClasses();
  const getIcon = () => {
    if (icon === CRITICAL_ICON) {
      return <FontAwesomeIcon icon={faExclamationSquare} className={styles.critical} size="lg"/>;
    }

    if (icon === WARNING_ICON) {
      return <FontAwesomeIcon icon={faExclamationTriangle} className={styles.warning} size="lg"/>;
    }

    return <FontAwesomeIcon icon={faInfoCircle} className={styles.info} size="lg"/>;
  };

  return (
  <>
    <Button
      classes={buttonClasses}
      variant="outlined"
      aria-label={aria}
      className={styles.messageButton}
      onClick={() => {
        navigate(route);
        onAction();
      }}
      color="primary"
      >
      {getIcon()}
      {title}
    </Button>
    <FormHelperText className={styles.helpText}>
    {messageTwo ? (<>{message}<br/>{messageTwo}</>) : `${message}`}
    </FormHelperText>
  </>
  );
};

NavMessage.propTypes = {
  icon: string.isRequired,
  title: string.isRequired,
  message: string.isRequired,
  messageTwo: string,
  type: string.isRequired,
  route: string.isRequired,
  aria: string.isRequired,
  onAction: func,
};

export default NavMessage;
