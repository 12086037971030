import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import {
  green, red, blue, orange,
} from '@material-ui/core/colors';

const baseIcon = {
  marginRight: theme.spacing(1),
};

export default makeStyles({
  success: {
    ...baseIcon,
    color: green[600],
  },
  error: {
    ...baseIcon,
    color: red[600],
  },
  critical: {
    ...baseIcon,
    color: red[600],
  },
  info: {
    ...baseIcon,
    color: blue[500],
  },
  warning: {
    ...baseIcon,
    color: orange[700],
  },
  messageButton: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
  },
  helpText: {
    overflowWrap: 'break-word',
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.fontSize,
  },
});

export const useButtonClasses = makeStyles({
  root: {
    display: 'block',
    textAlign: 'left',
    marginBottom: theme.spacing(1.5),
    width: '100%',
  },
});
